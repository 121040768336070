@use "../../styles/_variables" as *;
@use "../../styles/_mixins" as *;

.projects-scroll{
    width: 100%;
    overflow:auto;
    white-space: nowrap;
    margin: 1.5rem 0 0.5rem 0;
    -webkit-overflow-scrolling: touch;
}
.project-card {
    width: 200px;
    display: inline-block;
    margin-right: 1.5rem;
    @include tablet {
        width: 230px;
    }
    &:hover {
        scale: 0.9;
        transition: 0.6s;
        cursor: pointer;  
    }
    &__container {
        border-radius: 3px;
        padding: 0.7rem;
        background-color: white;
    }
    &__image {
        width: 100%;
        height: 120px;
        object-fit: cover;
        object-position:top;
        border-radius: 4px;
    }
    &__content {
        color: white;
        margin-top: 0.5rem;
        padding-top: 0.7rem;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__name {
        font-size: 0.8rem;
        font-weight: 600;
    }
    &__desc {
        font-size: 0.8rem;
        overflow-wrap: break-word;
    }
    &__icon {
        width: 25px;
    };
}
