body {
  box-sizing:border-box;
  font-family: 'Montserrat', sans-serif;
}
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}