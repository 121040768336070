@use "../../styles/_variables" as *;
@use "../../styles/_mixins" as *;

.homepage {
    background-color: $blue;
    color: white;
    &__hero {
        display: flex;
        height: 88vh;
    }
    &__about {
        margin: 3rem 0;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        @include tablet {
            margin: 3.5rem 7% 10rem 7%;
            flex-direction: row;
            gap: 5rem;
        }
        @include desktop{
            padding: 0 10%;
        }
    }
    &__projects {
        margin-bottom: 3rem;
        padding: 0 1rem 3rem 1rem;
        @include tablet {
            margin: 3.5rem 7% 0 7%;
        }
        @include desktop{
            padding: 0 10% 3rem 10%;
        }
    }
}
.hero{
    &__left {
        padding: 0 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include tablet{
            padding-left: 7%;
            width: 50%;
        }
        @include desktop{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    &__right {
        display: none;
        @include tablet {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            padding-right: 2.5rem;
        }
    }
    &__sun {
        width: 100px;
    }
    &__forest {
        width: 85%;
        max-width: 435px;
        @include desktop{
            max-width: 600px;
        }
    }
    &__title{
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 2.6rem;
        @include desktop{
            font-size: 2.3rem;
            line-height: 3.2rem;
        }
    }
    &__detail {
        font-size: 0.9rem;
        font-weight: 500;
        margin: 1.5rem 0;
        line-height: 1.5rem;
        @include tablet{
            font-weight: 600;
            margin: 2rem 0;
        }
        @include desktop{
            font-size: 1.1rem;
            margin: 2.5rem 0;
        }
    }
    &__name {
        color: $green;
    }
    &__btn {
        border: solid 1.5px $green;
        background-color: transparent;
        color: $green;
        padding: 0.5rem 1.5rem;
        border-radius: 25px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 0.8rem;
        cursor: pointer;
        @include desktop {
            font-size: 0.9rem;
            width: fit-content;
        }
    }
}
.about {
    &__content {
        display: flex;
        flex-direction: column;
        @include tablet{
            flex-direction: row;
            align-items: center;
            gap: 1.5rem;
        }
        @include desktop {
            gap: 3rem;
        }
    }
    &__info {
        margin: 2rem 0;
        @include tablet {
            order: 1;
            margin: 0;
        }
    }
    &__avatar {
        width: 65%;
        margin: 0 auto;
        @include tablet {
            order: 2;
            width: 35%;
        }
        @include desktop{
            width: 30%;
        }
    }
    &__title-container {
        @include section-title-container;
        width: fit-content;
        @include tablet {
            width: 100%;
        }
        @include desktop{
            width: 70%;
        }
    }
    &__title {
        @include section-title;
    }
    &__border {
        @include section-title-border;
    }
    &__skills {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-top: 1rem;
    }
    &__skill {
        font-size:12px;
        font-weight: 600;
        background-color: rgba(white, 0.1);
        border-radius: 15px;
        color: white;
        padding: 0.1rem 0.5rem;
        flex-grow: 1;
        text-align: center;
    }
    &__detail {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 2.5rem;
        line-height: 1.35rem;
        @include desktop {
            margin-bottom: 3rem;
            font-size: 14px;
            line-height: 1.45rem;
        }
    }
    &__btn-container {
        display: flex;
        justify-content: center;
        @include tablet {
            justify-content: start;
        }
    }
    &__btn {
        color: $green;
        border-radius: 20px;
        background-color: transparent;
        border: solid 1px $green;
        padding: 0.4rem 1.2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 0.7rem;
        &:hover {
            border: solid 1px white;
            color: white;
            transition: 0.5s;
        }
        @include tablet {
            font-size: 0.7rem;
            padding: 0.45rem 1.4rem;
        }
    }
    &__question {
        color: $green;
        font-weight: 600;
        margin-bottom: 0.5rem;
        font-size: 14px;
    }
}
.projects {
    &__project {
        margin-bottom: 2.5rem;
        @include tablet{
            margin-bottom: 5rem;
        }
    }
    &__title-container {
        @include section-title-container;
        margin-bottom: 4rem;
        @include tablet {
            margin-bottom: 5rem;
        }
        @include desktop{
            margin-bottom: 5rem;
        }
    }
    &__title {
        @include section-title;
    }
    &__border {
        @include section-title-border;
    }
}
.project {
    &__content{
        @include tablet{
            display: flex;
            gap: 2.5rem;
            padding-top: 2rem;
            align-items: start;
        }   
    }
    &__info {

    }
    &__title{
        @include project-title;
        @include desktop {
            margin-bottom: 1.5rem;
        }
    }
    &__img {
        width: 100%;
        border-radius: 5px;
        margin: 1rem 0;
        object-fit: contain;
        @include tablet{
            margin: 0;
            width: 30%;
        }
    }
    &__techstacks {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }
    &__item {
        width: 25%;
        background-color: rgba(white, 0.1);
        color: white;
        padding: 0.2rem 0.5rem;
        font-size: 0.7rem;
        text-align: center;
        @include desktop {
            font-size: 0.8rem;
        }
    }
    &__detail{
        margin: 1.5rem 0;
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 1.3rem;
        @include tablet{
            margin: 1.5rem 0;
        }
        @include desktop{
            font-size: 0.9rem;
            line-height: 1.4rem;
        }
    }
    &__btn-container {
        display: flex;
        justify-content: end;
        position: relative;
        margin-top: 1rem;
        @include tablet {
            justify-content: start;
            margin-top: 2rem;
        }
    }
    &__btn {
        background-color: white;
        color: $blue;
        border: none;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        height: 30px;
        width: 120px;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        @include tablet{
            left: 0.5rem;
            bottom: 0.5rem;
        }
        &:hover {
            background-color: darkorange;
            transition: 0.3s;
        }
    }
    &__btn-bg{
        background-color: $green;
        height: 30px;
        width: 120px;
    }
    &__archive-link {
        color: black;
        text-decoration: none;
        color: $green;
        cursor: pointer;
        &:hover {
            border-bottom: solid 2px $green;
            transition: 0.5s;
            padding-bottom: 0.2rem;
        }
    }
    &__archive{
        width: 20px;
        margin-bottom: -0.2rem;
        margin-left: 0.5rem;
    }
}

.m-bottom{
    display: block;
    margin-bottom: 1rem;
}